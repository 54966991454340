import { Link } from 'gatsby';
import React from 'react';

import SingleOffer from './components/SingleOffer/SingleOffer';
import * as S from './style/offer.style';

const products = [
    {
        new: true,
        image: 'https://www.skinlab.pl/wp-content/themes/skinlab/raman.svg',
        title: 'Blue Light – HEV (high-energy visible light)',
        linkUrl: 'blue-light-hev-high-energy-visible-light',
        description:
            'Badanie (in vitro) skuteczności produktów kosmetycznych przeciw negatywnemu działaniu światła niebieskiego na skórę',
    },
    {
        new: true,
        image: 'https://www.skinlab.pl/wp-content/themes/skinlab/raman.svg',
        title: 'Blue Light – HEV (high-energy visible light)',
        linkUrl: 'blue-light-hev-high-energy-visible-light',
        description:
            'Badanie (in vitro) skuteczności produktów kosmetycznych przeciw negatywnemu działaniu światła niebieskiego na skórę',
    },
    {
        new: true,
        image: 'https://www.skinlab.pl/wp-content/themes/skinlab/raman.svg',
        title: 'Blue Light – HEV (high-energy visible light)',
        linkUrl: 'blue-light-hev-high-energy-visible-light',
        description:
            'Badanie (in vitro) skuteczności produktów kosmetycznych przeciw negatywnemu działaniu światła niebieskiego na skórę',
    },
    {
        new: true,
        image: 'https://www.skinlab.pl/wp-content/themes/skinlab/raman.svg',
        title: 'Blue Light – HEV (high-energy visible light)',
        linkUrl: 'blue-light-hev-high-energy-visible-light',
        description:
            'Badanie (in vitro) skuteczności produktów kosmetycznych przeciw negatywnemu działaniu światła niebieskiego na skórę',
    },
    {
        new: false,
        image: 'https://www.skinlab.pl/wp-content/themes/skinlab/raman.svg',
        title: 'Blue Light – HEV (high-energy visible light)',
        linkUrl: 'blue-light-hev-high-energy-visible-light',
        description:
            'Badanie (in vitro) skuteczności produktów kosmetycznych przeciw negatywnemu działaniu światła niebieskiego na skórę',
    },
    {
        new: false,
        image: 'https://www.skinlab.pl/wp-content/themes/skinlab/raman.svg',
        title: 'Blue Light – HEV (high-energy visible light)',
        linkUrl: 'blue-light-hev-high-energy-visible-light',
        description:
            'Badanie (in vitro) skuteczności produktów kosmetycznych przeciw negatywnemu działaniu światła niebieskiego na skórę',
    },
];

const Offer = () => {
    return (
        <div>
            <S.OfferList>
                {products.map((item, index) => (
                    <SingleOffer offer={item} key={index} />
                ))}
            </S.OfferList>
        </div>
    );
};

export default Offer;
