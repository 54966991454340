import styled from 'styled-components';

const ClientContainer = styled.li`
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ClientImage = styled.img`
    width: 130px;
    height: 130px;
    cursor: pointer;
`;

export { ClientContainer, ClientImage };
