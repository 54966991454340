import React from 'react';

import * as S from './style/mapSection.style';

const MapSection = () => {
    return (
        <S.MapContainer>
            <img
                src="https://www.skinlab.pl/wp-content/uploads/2019/10/193592075_4.jpg"
                alt="mapImage"
            />
        </S.MapContainer>
    );
};

export default MapSection;
