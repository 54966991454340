import styled from 'styled-components';

const OfferContainer = styled.li`
    max-width: 360px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin: 0px 20px;
    align-items: center;
`;

const OfferImage = styled.img`
    height: 80px;
    width: 80px;
`;

const OfferTitle = styled.h1`
    font-size: 1rem;

    a {
        text-decoration: none;
        color: black;
    }
`;

export { OfferContainer, OfferImage, OfferTitle };
