import styled from 'styled-components';

const PostsList = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    list-style: none;
    padding: 65px 0 70px;
`;

export { PostsList };
