import styled from 'styled-components';

const ClientList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    list-style: none;
`;

export { ClientList };
