import React from 'react';

import PostPreview from './components/PostPreview/PostPreview';
import * as S from './style/blogPreview.style';

const postsLists = [
    {
        image: 'https://www.skinlab.pl/wp-content/uploads/2019/07/shutterstock_648901270-360x240.jpg',
        tag: {
            name: 'Bezpieczeństwo kosmetyków',
            url: 'bezpieczenstwo-kosmetykow',
        },
        title: 'Ustawa o kosmetykach z dnia 4 października 2018 roku',
        url: 'ustawa-o-kosmetykach-z-dnia-4-pazdziernika-2018-roku',
        description:
            'Nowa ustawa o kosmetykach – nowe prawo 1 lipca 2019 r. zaczęła obowiązywać nowa ustawa o produktach kosmetycznych z dnia 4 października 2018 r. Rynek kosmetyczny z niecierpliwością czekał na nowe regulacje prawne, które mają',
    },
    {
        image: 'https://www.skinlab.pl/wp-content/uploads/2019/07/shutterstock_648901270-360x240.jpg',
        tag: {
            name: 'Bezpieczeństwo kosmetyków',
            url: 'bezpieczenstwo-kosmetykow',
        },
        title: 'Ustawa o kosmetykach z dnia 4 października 2018 roku',
        url: 'ustawa-o-kosmetykach-z-dnia-4-pazdziernika-2018-roku',
        description:
            'Nowa ustawa o kosmetykach – nowe prawo 1 lipca 2019 r. zaczęła obowiązywać nowa ustawa o produktach kosmetycznych z dnia 4 października 2018 r. Rynek kosmetyczny z niecierpliwością czekał na nowe regulacje prawne, które mają',
    },
    {
        image: 'https://www.skinlab.pl/wp-content/uploads/2019/07/shutterstock_648901270-360x240.jpg',
        tag: {
            name: 'Bezpieczeństwo kosmetyków',
            url: 'bezpieczenstwo-kosmetykow',
        },
        title: 'Ustawa o kosmetykach z dnia 4 października 2018 roku',
        url: 'ustawa-o-kosmetykach-z-dnia-4-pazdziernika-2018-roku',
        description:
            'Nowa ustawa o kosmetykach – nowe prawo 1 lipca 2019 r. zaczęła obowiązywać nowa ustawa o produktach kosmetycznych z dnia 4 października 2018 r. Rynek kosmetyczny z niecierpliwością czekał na nowe regulacje prawne, które mają',
    },
];

const BlogPreview = () => {
    return (
        <div>
            <h1>Blog</h1>
            <S.PostsList>
                {postsLists.map((post, index) => (
                    <PostPreview post={post} key={index} />
                ))}
            </S.PostsList>
        </div>
    );
};

export default BlogPreview;
