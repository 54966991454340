import { WindowCtx, LangCtx, useContextState } from '@components/contexted';
import { Layout } from '@components/layout';
import { Breadcrumbs } from '@components/shared';
import {
    AboutUs,
    Offer,
    MapSection,
    Clients,
    BlogPreview,
    Contact,
} from '@components/standard';
import type { IPage, IPost, IProduct, ILocation } from '@standardTypes';
import React from 'react';

interface HomeProps {
    pageContext: {
        page: IPage;
        pages: Array<IPage>;
        translationUrls: Object;
    };
    location: ILocation;
}

const IndexPage: React.FC<HomeProps> = ({ pageContext, location }) => {
    const { page, translationUrls } = pageContext;
    const { language } = useContextState(LangCtx, ['language']);
    return (
        <Layout {...page} translationUrls={translationUrls}>
            <p>{content[language].title}</p>
            <AboutUs />
            <Offer />
            <MapSection />
            <Clients />
            <BlogPreview />
            {/* <Contact /> */}
        </Layout>
    );
};

export default IndexPage;

// 6. Contact

const content = {
    pl: {
        title: 'Aktualny język to język polski',
    },
    en: {
        title: 'Selected language english',
    },
};
