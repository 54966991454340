import styled from 'styled-components';

const ContactSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
    background-color: #444;
    height: 100vh;
`;

const ContentContainer = styled.div`
    width: 40%;
`;

export { ContactSection, ContentContainer };
