import React from 'react';

import SingleClient from './components/SingleClient/SingleClient';
import * as S from './style/clients.style';

const clientList = [
    {
        image: 'https://www.skinlab.pl/wp-content/uploads/2017/11/kosmetyczny.jpg',
        url: '',
        name: 'Przemysłu kosmetycznego',
    },
    {
        image: 'https://www.skinlab.pl/wp-content/uploads/2017/11/kosmetyczny.jpg',
        url: '',
        name: 'Przemysłu kosmetycznego',
    },
    {
        image: 'https://www.skinlab.pl/wp-content/uploads/2017/11/kosmetyczny.jpg',
        url: '',
        name: 'Przemysłu kosmetycznego',
    },
    {
        image: 'https://www.skinlab.pl/wp-content/uploads/2017/11/kosmetyczny.jpg',
        url: '',
        name: 'Przemysłu kosmetycznego',
    },
    {
        image: 'https://www.skinlab.pl/wp-content/uploads/2017/11/kosmetyczny.jpg',
        url: '',
        name: 'Przemysłu kosmetycznego',
    },
];

const Clients = () => {
    return (
        <div>
            <h1>Swoją ofertę kierujemy do:</h1>
            <S.ClientList>
                {clientList.map((item, index) => (
                    <SingleClient key={index} client={item} />
                ))}
            </S.ClientList>
        </div>
    );
};

export default Clients;
