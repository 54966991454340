import styled from 'styled-components';

const MapContainer = styled.div`
    height: 100vh;
    background-color: #103755;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export { MapContainer };
