import { Link } from 'gatsby';
import React from 'react';

import * as S from './style';

interface PostPreviewProps {
    post: any;
}

const PostPreview = ({ post }: PostPreviewProps) => {
    return (
        <S.PostContainer>
            <Link to={`/${post.url}`}>
                <img src={post.image} />
            </Link>
            <Link to={`/${post.tag.url}`}>{post.tag.name}</Link>
            <strong>
                <Link to={`/${post.url}`}>{post.title}</Link>
            </strong>
            <p>{post.description}</p>
            <Link to={`/${post.url}`}>Więcej...</Link>
        </S.PostContainer>
    );
};

export default PostPreview;
