import { Link } from 'gatsby';
import React from 'react';

import * as S from './style';

interface SingleClientProps {
    client: any;
}

const SingleClient = ({ client }: SingleClientProps) => {
    return (
        <S.ClientContainer>
            <Link to={`/${client.url}`}>
                <S.ClientImage src={client.image} />
            </Link>
            <p>{client.name}</p>
        </S.ClientContainer>
    );
};

export default SingleClient;
