import React from 'react';
import { useActions, LangCtx, useContextState } from '@components/contexted';

const AboutUs = () => {
    const { language } = useContextState(LangCtx, ['language']);
    return (
        <div>
            {content[language].title}
            {content[language].paragraph1}
            {content[language].paragraph2}
            {content[language].paragraph3}
            {content[language].paragraph4}
            {content[language].paragraph5}
            {content[language].paragraph6}
            {content[language].paragraph7}
        </div>
    );
};

export default AboutUs;

const content = {
    pl:{
        title:<h1>Witamy w Skin Lab International Sp. z o.o.</h1>,
        paragraph1:<p>Specjalistyczne Laboratorium Badawcze Skin Lab International Sp. z o. o. jest dynamicznie rozwijającym się ośrodkiem badawczym, wykonującym kompleksowy pakiet badań i analiz,. Specjalizuje się w badaniach dermatologicznych, aplikacyjnych i wdrożeniowych dla przemysłu kosmetycznego i chemicznego oraz w badaniach specjalistycznych dla przemysłu lekkiego.</p>,
        paragraph2:<p>
            Pozytywny wizerunek firmy kształtujemy przede wszystkim poprzez profesjonalizm oraz pełne zaangażowanie w wykonywaną pracę. Naszym atutem jest doświadczona kadra wysoko wykwalifikowanych pracowników z zakresu chemii, farmacji, mikrobiologii i medycyny (dermatologii, ginekologii, pediatrii). Laboratorium posiada własną bazę ochotników (probantów/wolontariuszy), a w celu zapewnienia najwyższej jakości usług -  samodzielnie prowadzimy rekrutację do wszystkich badań.
        </p>,
        paragraph3:<p>
            Mając świadomość, że nasi pracownicy stanowią tylko jeden z wielu elementów sukcesu, oferujemy również najnowocześniejsze techniki i metody badawcze. Najwyższy poziom usług oraz wysoką precyzję otrzymanych wyników umożliwia praca na najlepszym sprzęcie laboratoryjnym. Laboratorium Skin Lab International wspierane jest przez własny Doświadczalny Zakład Badań Kosmetyków, w którym profesjonaliści z zakresu kosmetologii i medycyny przeprowadzają zaawansowane badania in-vivo dla produktów (w tym też produktów profesjonalnych dedykowanych do medycyny estetycznej).
        </p>,
        paragraph4:<p>
            Nadzór merytoryczny doświadczonego i wyszkolonego personelu, najnowsze techniki, innowacyjne metody, światowej klasy aparaturowa pomiarowa, własne Gabinety Aplikacyjne, Dermatologiczne i Kosmetyczne oraz praca zgodnie z zasadami ISO 17025, Dobrej Praktyki Badań Klinicznych (ICH GCP), procedurami wewnętrznymi oraz z wymogami stawianymi przez Rozporządzenie Kosmetyczne Nr 1223/2009, Ustawę o kosmetykach z dnia 4 października 2018 r. i Dyrektywą Komisji 95/45/WE, zapewniają rzetelność wyników badań.

        </p>,
        paragraph5:<p>
            Laboratorium Skin Lab International jest wiarygodnym partnerem. Gwarantujemy wysoką jakość naszych usług, terminowość oraz poufność wykonywanych badań i wyników.
        </p>,
        paragraph6:<p>
            Nasze badania potwierdzają: jakość, skuteczność i bezpieczeństwo produktów.
        </p>,
        paragraph7:<p>
            Oferujemy fachowe konsultacje z zakresu badań, przepisów prawa i całej drogi wdrażania produktów na rynek UE.
        </p>
    },
    en:{
       title:<h1>Welcome to Skin Lab International Sp. z o.o.</h1>,
        paragraph1:<p>Specialized Research Laboratory Skin Lab International Sp. z o. o. is a dynamically developing research centre, performing a comprehensive package of research and analysis,. It specialises in dermatological, application and implementation research for the cosmetics and chemical industries, as well as specialist research for the light industry.</p>,
        paragraph2:<p>
            We create a positive image of our company primarily through professionalism and full commitment to the work we do. Our advantage is an experienced staff of highly qualified personnel in the field of chemistry, pharmacy, microbiology and medicine (dermatology, gynaecology, paediatrics). The laboratory has its own base of volunteers (probants/volunteers), and in order to ensure the highest quality of services - we conduct recruitment for all tests ourselves.
        </p>,
        paragraph3:<p>
            Recognising that our people are just one element of our success, we also offer state-of-the-art research techniques and methods. The highest level of service and high precision of results is achieved by using the best laboratory equipment. Skin Lab International is supported by its own Experimental Department of Cosmetics Research, where professionals in the field of cosmetology and medicine conduct advanced in-vivo tests for products (including professional products dedicated to aesthetic medicine).
        </p>,
        paragraph4:<p>
            Substantive supervision by experienced and trained personnel, state-of-the-art techniques, innovative methods, world-class measurement apparatus, in-house Application, Dermatology and Cosmetics Cabinets and working in accordance with ISO 17025, Good Clinical Research Practice (ICH GCP), internal procedures and with the requirements set by Cosmetic Regulation No. 1223/2009, the Cosmetics Act of 4 October 2018 and Commission Directive 95/45/EC, ensure the reliability of test results.

        </p>,
        paragraph5:<p>
            Skin Lab International is a reliable partner. We guarantee the high quality of our services, timeliness and confidentiality of our tests and results.
        </p>,
        paragraph6:<p>
            Our research confirms: the quality, efficacy and safety of our products.
        </p>,
        paragraph7:<p>
            We offer expert consultancy on research, legislation and the entire path to market in the EU.
        </p>
    }
}
