import styled from 'styled-components';

const OfferList = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
`;

export { OfferList };
