import styled from 'styled-components';

const PostContainer = styled.li`
    width: 33%;
    display: flex;
    flex-direction: column;
    text-align: left;

    img {
        width: 100%;
    }
`;

export { PostContainer };
