import { Link } from 'gatsby';
import React from 'react';

import * as S from './styles';

interface SingleOfferProps {
    offer: any;
}

const SingleOffer: React.FC<SingleOfferProps> = ({ offer }) => {
    return (
        <S.OfferContainer>
            {offer.image && <S.OfferImage src={offer.image} loading="lazy" />}
            <S.OfferTitle>
                <Link to={`/${offer.linkUrl}`}>{offer.title}</Link>
            </S.OfferTitle>
            <p>{offer.description}</p>
            <span>New: {String(offer.new)}</span>
        </S.OfferContainer>
    );
};

export default SingleOffer;
